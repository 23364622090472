@import '../../theme'

.contacts
  display: flex
  align-items: center
  gap: 1rem

.contactItem
  color: $text-color
  transition: color .2s ease-in-out
  text-decoration: none
  font-family: OnestBlack, sans-serif

@media (hover: hover)
  .contactItem:hover
    color: $brand-color

@media screen and (max-width: 1280px)
  .contacts
    flex-direction: column
    align-items: flex-start