@import '../../theme'

.slider
  border-radius: .8rem

  button
    position: absolute
    bottom: -4rem
  .next_arrow
    left: 4rem

.sliderItem
  border-radius: .8rem
  overflow: hidden

  img
    max-width: 100%

.sliderCounter
  position: absolute
  bottom: -2.5rem
  font-family: OnestLite, sans-serif
  right: 0
  font-size: 1.3rem
  color: $grey600-color