.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12
  padding: 0 .5rem

.col-1
  width: calc(100% / 12)

.col-2
  width: calc(100% / 12 * 2)

.col-3
  width: calc(100% / 12 * 3)

.col-4
  width: calc(100% / 12 * 4)

.col-5
  width: calc(100% / 12 *5)

.col-6
  width: 50%

.col-7
  width: calc(100% / 12 * 7)

.col-8
  width: calc(100% / 12 * 8)

.col-9
  width: calc(100% / 12 * 9)

.col-10
  width: calc(100% / 12 * 10)

.col-11
  width: calc(100% / 12 * 11)

.col-12
  width: 100%


@media screen and (max-width: 768px)
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12
    width: 100% !important