@import './theme'

@font-face
  font-weight: 100
  font-family: OnestExtraLite
  src: url("../public/fonts/Onest-ExtraLight.ttf")
  font-display: fallback

@font-face
  font-weight: 200
  font-family: OnestLite
  src: url("../public/fonts/Onest-Light.ttf")
  font-display: fallback

@font-face
  font-weight: 300
  font-family: OnestThin
  src: url("../public/fonts/Onest-Thin.ttf")
  font-display: fallback

@font-face
  font-weight: 400
  font-family: OnestRegular
  src: url("../public/fonts/Onest-Regular.ttf")
  font-display: fallback

@font-face
  font-weight: 500
  font-family: OnestMedium
  src: url("../public/fonts/Onest-Medium.ttf")
  font-display: fallback

@font-face
  font-weight: 600
  font-family: OnestBold
  src: url("../public/fonts/Onest-Bold.ttf")
  font-display: fallback

@font-face
  font-weight: 700
  font-family: OnestSemiBold
  src: url("../public/fonts/Onest-SemiBold.ttf")
  font-display: fallback

@font-face
  font-weight: 800
  font-family: OnestExtraBold
  src: url("../public/fonts/Onest-ExtraBold.ttf")
  font-display: fallback

@font-face
  font-weight: 900
  font-family: OnestBlack
  src: url("../public/fonts/Onest-Black.ttf")
  font-display: fallback

body
  overflow-x: hidden

*
  padding: 0
  margin: 0
  box-sizing: border-box
  position: relative
  font-size: 16px
  font-family: OnestRegular, sans-serif
  outline: none

.foot h4
  color: $grey600-color
  margin-bottom: 2rem
  font-family: OnestMedium, sans-serif

.men
  padding-right: 2rem

.foot a, .foot div
  font-size: .9rem !important

h1, h2, h3, h4, h5
  font-family: OnestExtraBold, sans-serif
  letter-spacing: -3%
  line-height: 100%

h2, h3, h4
  letter-spacing: -2%

h1
  font-size: 4.3rem
  text-transform: uppercase

h2
  font-size: 2.8rem
  text-transform: uppercase
  margin-bottom: 2.4rem

  span
    font-size: 3.2rem
    text-transform: uppercase
    color: $brand-color
    font-family: OnestExtraBold, sans-serif

h3
  font-size: 2.1rem

h4
  font-size: 1.5rem

h5
  font-size: 1rem
  letter-spacing: 0

p
  margin-top: 1rem
  line-height: 150%

.menu_link
  color: $white-color
  font-family: OnestBold, sans-serif
  transition: color 0.2s ease-in-out
  border-bottom: 1px solid #37353D
  padding: 1rem 0
  cursor: pointer

  &:hover
    color: $brand-color

  &:first-child
    padding-top: 0


.activeMarker
  filter: invert(2)

.hidden_desktop
  display: none !important

.oneNews
  img
    max-width: 100%

  h1
    font-size: 2.8rem

  p, ul, ol
    padding-bottom: 2rem

    &:last-child
      padding-bottom: 0

  ul, ol
    margin-left: 2rem
    li
      margin-bottom: 1rem
      line-height: 1.5rem
      &:last-child
        margin-bottom: 0

.pt-all2
  padding-top: 3rem !important

.item
  display: flex
  justify-content: space-between
  align-items: flex-start
  gap: 1rem


@media screen and (max-width: 1280px)
  .hidden_mobile
    display: none !important

  .hidden_desktop
    display: flex

  .reverse
    flex-direction: column-reverse

  .w100
    width: 100% !important

  .mt2
    margin-top: 2rem

  .mb2
    margin-bottom: 4rem

  .w50
    width: 50% !important

  .dirCol
    flex-direction: column !important
    align-items: flex-start !important

  .contacts
    display: flex !important
    flex-direction: column
    gap: 2rem
    margin-bottom: 2rem
    padding-bottom: 2rem
    border-bottom: 1px solid $grey600-color

    a, div
      color: $white-color

amp-img
  display: flex



@media screen and (max-width: 768px)
  .reverse2
    flex-direction: column-reverse

  .mb1
      margin-bottom: 1rem

  .dirCol
    flex-direction: column

@media screen and (max-width: 505px)
  h1
    font-size: 1.8rem

  h2
    font-size: 1.6rem

  button
    width: 100%

  .filter
    flex-direction: column

    button, a
      width: 100% !important

  .mob
    button, a
      width: 100% !important

  .men
    padding-right: 0 !important
