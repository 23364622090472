@import '../../theme'

.module
  padding: 2.2rem
  border-radius: .8rem
  background-color: $grey-color
  border: 1px solid $grey800-color

  p
    padding-right: 10rem
    padding-bottom: 3rem

  &.dark
    padding: 0 1rem 1rem
    background-color: $text-color

    img
      border-radius: .8rem

    p, h3
      color: $white-color
      padding-right: 0

    p
      padding-bottom: 0

    a
      padding-top: 2rem
      text-decoration: none
      color: $brand-color
      display: flex
      gap: .5rem
  h3
    font-size: 1.4rem
    margin-bottom: 1rem
    padding-top: 0
    margin-top: 0


@media screen and (max-width: 1280px)
  .module
    p
      padding-right: 0
      padding-bottom: 0

@media screen and (max-width: 500px)
  .module
    padding: 1rem