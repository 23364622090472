@import '../../theme'

.banner
  height: 50rem
  width: 100%
  padding: 2.8rem
  margin-top: 5rem

  &.bannerVideo
    color: $white-color
    &:before
      content: ''
      position: absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      background-color: $banner-bg-color


@media screen and (max-width: 500px)
  .banner
    padding: 2rem 0

    button, a
      width: 100% !important