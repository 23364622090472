@import "../../theme"

.bulitpoints
  display: flex
  flex-wrap: wrap
  gap: 1rem

.bulitpointItem
  display: flex
  align-items: center
  gap: 1rem
  background-color: $grey-color
  color: $text-color
  width: calc(50% - .5rem)
  padding: 1rem
  font-family: OnestBlack, sans-serif
  border-radius: .8rem

  img
    width: 3rem
    height: 3rem
    object-fit: contain


@media screen and (max-width: 500px)

  .bulitpointItem
    width: 100%