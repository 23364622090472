@import '../../theme'

.header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 1000
  background-color: $white-color

.header, .headerWrapper
  height: 5rem

.headerWrapper
  display: flex
  align-items: center
  justify-content: space-between

.logo
  display: flex
  align-items: center
  img
    max-height: 1.5rem


@media screen and (max-width: 1280px)
  .langs, .contacts
    display: none