@import '../../theme'

.ldsRing
  display: flex
  width: 4rem
  height: 3rem
  justify-content: center
  align-items: center
  //top: calc(50% - 40px)

  span
    box-sizing: border-box
    display: block
    position: absolute
    width: 1.2rem
    height: 1.2rem
    margin: 1rem
    border: .1rem solid
    border-radius: 50%
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: $text-color transparent transparent transparent

    &:nth-child(1)
      animation-delay: -0.45s

    &:nth-child(2)
      animation-delay: -0.3s

    &:nth-child(3)
      animation-delay: -0.15s

@keyframes ldsRing
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
