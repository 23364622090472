.buttonBox
  display: flex
  align-items: center
  gap: 1rem

@media screen and (max-width: 500px)
  .buttonBox
    flex-direction: column

    button
      width: 100%