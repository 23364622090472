@import '../../theme'

.cookie
  position: fixed
  bottom: 1rem
  left: 1rem
  padding: 2rem
  background: $text-color
  backdrop-filter: blur(6px)
  border-radius: 2rem
  max-width: 33rem
  z-index: 2000
  border: 1px solid $grey200-color

  .text

    h4
      color: $white-color

    p
      color: $white-color
      line-height: 1.2rem

      a
        color: $brand-color
        transition: color .2s ease-in-out

  .buttons
    margin-top: 3rem
    display: flex
    align-items: center
    justify-content: space-between
    gap: 1rem

    .button
      display: flex
      align-items: center
      justify-content: space-between
      gap: 1rem

      button
        min-width: 7rem
        width: 100%
        font-family: InterSemiBold, sans-serif

@media (hover: hover)
  .cookie
    .text
      p
        a:hover
          color: $white-color


.cookieSettings__wrapper
  position: fixed
  z-index: 2024
  background-color: $text-color
  width: 100vw
  height: 100vh
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  justify-content: center
  padding-top: 4rem
  backdrop-filter: blur(6px)
  overflow: auto

  .cookieSettings__container
    width: 33rem
    padding: 2rem
    border-radius: 2rem
    background: $text-color
    backdrop-filter: blur(6px)
    height: min-content
    overflow: auto

    h4, h5
      color: $white-color
      margin-bottom: 2rem

    h5
      margin-bottom: 0

    p
      color: $grey600-color
      line-height: 1.2rem
      margin-bottom: 1.2rem

      a
        color: $brand-color
        transition: color .2s ease-in-out

  .buttons
    margin-top: 3rem
    display: flex
    align-items: center
    justify-content: space-between
    gap: 1rem

    .button
      display: flex
      align-items: center
      justify-content: space-between
      gap: 1rem

      button
        min-width: 10rem
        width: 100%
        font-family: InterSemiBold, sans-serif


@media screen and (max-width: 768px)
  .cookie, .cookieSettings__container
    width: 100%
    max-width: calc(100% - 2rem)

  .cookie
    .buttons
      flex-wrap: wrap
      .button
        width: 100%
        flex-wrap: wrap
        button
          min-width: 40%
          width: 100%

  .cookieSettings__wrapper
    padding-top: 0
    .cookieSettings__container
      left: 0
      right: 0
      border-radius: 0
      overflow: auto
      width: 100%
      max-width: 100%
      height: 100vh
    .buttons
      .button
        width: 100%
        flex-wrap: wrap


