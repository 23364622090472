$brand-color: #F79B5D
$text-color: #1C1A24
$white-color: #FBF9F9
$bg-color: #FBF8F8
$grey-color: #F6F1EF
$grey800-color: #DBD8D8
$grey600-color: #999797
$grey200-color: #37353D
$banner-bg-color: rgba(18,18,33,.8)

$border-radius: .5rem

$border: .07rem solid

