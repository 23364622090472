@import '../../theme'

.headerMenu
  position: fixed
  top: 5rem
  z-index: 1000
  left: 0
  right: 0
  padding: 3rem 0
  border-bottom: 1px solid $grey200-color
  background-color: $text-color
  display: none

  &.headerMenuActive
    display: flex

  a
    text-decoration: none