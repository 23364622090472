.section
  display: flex
  flex-wrap: wrap

  &.section_vertical
    flex-direction: column

  &.section_full
    height: 100%
    justify-content: space-between

  &.positionEnd
    justify-content: flex-end

.section_margin
  margin-top: 10rem


@media screen and (max-width: 1280px)
  .section_margin
    margin-top: 6rem

@media screen and (max-width: 500px)
  .section
    width: 100%

    &.section_full
      justify-content: unset