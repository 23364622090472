@import '../../theme'

.menu
  display: flex
  flex-direction: column

  a
    font-family: OnestBold, sans-serif
    color: $white-color
    transition: color .2s ease-in-out
    border-bottom: 1px solid $grey200-color
    padding: 1rem 0

    &:first-child
      padding-top: 0

    &:last-child
      border-bottom: none

    &.active
      color: $brand-color

@media (hover: hover)
  a:hover
    color: $brand-color