.container
  width: 1516px
  margin: auto
  max-width: 100%

  &.container_full
    height: 100%

  &.container_full_width
    width: 100vw


@media screen and (max-width: 1920px)
  .container
    width: 1200px

@media screen and (max-width: 1280px)
  .container
    width: 687px

//@media screen and (max-width: 991px)
//  .container
//    width: 687px

@media screen and (max-width: 500px)
  .container
    width: 100%
