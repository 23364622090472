@import '../../theme'

.langs
  display: flex
  align-items: center
  gap: 1rem

.langItem
  text-transform: uppercase
  font-family: OnestBlack, sans-serif
  cursor: pointer

  &.active
    color: $brand-color
    cursor: default
    &:after
      content: ''
      height: .1rem
      width: 100%
      position: absolute
      bottom: -.4rem
      background-color: $brand-color
      left: 0
