@import '../../theme'

.btn
  height: 3rem
  border-radius: $border-radius
  display: flex
  justify-content: center
  align-items: center
  gap: $border-radius
  color: $text-color
  font-family: OnestExtraBold, sans-serif
  transition: all .2s ease-in-out
  cursor: pointer
  min-width: 3rem
  padding: 1rem

  &.fill
    background-color: $brand-color
    border: $border $brand-color
    padding: 1rem 1.8rem

  &.border
    border: $border $grey200-color
    background-color: transparent
    text-decoration: none
    width: fit-content

  &.white
    color: $white-color
    border-color: $grey-color

  &.menu
    color: $white-color
    border: 1px solid $grey800-color
    background-color: $grey-color
    border-radius: 3rem
    display: flex
    align-items: center
    justify-content: center
    width: 3rem
    transition: all .2s ease-in-out

    span
      width: 2rem
      height: .1rem
      background-color: $grey-color
      transition: all .2s ease-in-out

      &:after, &:before
        content: ""
        width: 1.7rem
        height: .1rem
        background-color: $text-color
        position: absolute
        top: -.2rem
        left: -.4rem
        transition: all .2s ease-in-out

      &:before
        top: .2rem
  &.close
    background-color: $brand-color
    span
      width: 2rem
      height: .1rem
      background-color: $brand-color

      &:after, &:before
        content: ""
        width: 1.7rem
        height: .1rem
        background-color: $text-color
        position: absolute
        top: 0
        left: -.4rem
        transform: rotate(45deg)

      &:before
        top: 0
        transform: rotate(-45deg)

  &.border_icon
    border: 1px solid $grey800-color
    background-color: $grey-color

  &.slider
    width: 3rem
    height: 3rem
    border-radius: 2rem
    display: flex !important
    align-items: center
    justify-content: center
    border: none
    background-color: $brand-color

  &.social_icon
    background-color: transparent
    border: 1px solid $grey600-color
    width: 3rem
    height: 3rem

    &.social_icon_orange
      background-color: $brand-color
      border: none


@media (hover: hover)
  .btn
    &.fill:hover, &.border:hover
      background-color: $grey-color
      border-color: $grey-color

    &.white:hover
      color: $text-color
