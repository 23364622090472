@import '../../theme'

.footer
  background-color: $text-color
  margin-top: 10rem
  width: 100vw
  padding: 2.5rem

  p
    color: $white-color
    margin-top: 2rem

  a
    color: $white-color
    font-family: OnestBold, sans-serif
    text-decoration: none
    transition: color .2s ease-in-out

@media (hover: hover)
  a:hover
    color: $brand-color

@media screen and (max-width: 500px)



@media screen and (max-width: 1280px)
  .footer
    margin-top: 6rem


@media screen and (max-width: 500px)
  .footer
    padding: 2rem 0