@import '../../theme'

.card
  background-color: $grey-color
  border: 1px solid $grey800-color
  border-radius: .8rem
  padding: 1rem
  transition: margin-top .2s ease-in-out
  cursor: pointer
  display: flex
  text-decoration: none
  flex-direction: column
  color: $text-color
  margin-bottom: 1rem

  h3
    font-size: 1.5rem

    margin-bottom: 2rem

  &:hover
    color: $text-color

  img
    max-width: 100%
    height: auto
    max-height: 15rem
    object-fit: contain

  button
    width: 100%

    margin-top: 1rem

  .cardHeader
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid $grey800-color
    padding-bottom: 1rem
    margin-bottom: 1rem

    p
      margin-top: 0
      font-family: OnestBlack, sans-serif

      &:first-child
        text-transform: uppercase

      sup
        font-family: OnestBlack, sans-serif
        font-size: .8rem

  .cardContent
    margin-top: 1rem
    padding-top: 1rem
    border-top: 1px solid $grey800-color

    p
      margin-top: 0

      &:nth-child(2)
        font-size: 3rem
        font-family: OnestBlack, sans-serif
        text-align: center

      &:last-child
        text-align: right
        color: $grey600-color

      &:first-child
        color: $grey600-color

  .cardFooter
    margin-top: 3rem
    p
      font-family: OnestBlack, sans-serif

    ul
      margin-top: 1rem
      margin-left: 1.5rem

  &.cardSmall
    background-color: #fff
    border-radius: 0
    border-left: none
    border-top: none
    border-right: none
    margin-bottom: 1rem

    a
      color: $brand-color
      text-decoration: none
      font-family: OnestBold, sans-serif

    p
      margin-top: 0
      color: $grey600-color
      margin-bottom: 1rem

    h4
      margin-bottom: 1.5rem

    &:first-child
      margin-top: 0

    &:last-child
      border-bottom: none

  &.cardNews
    margin-bottom: 1rem
    transition: background-color .2s ease-in-out

    a
      color: $brand-color
      text-decoration: none
      font-family: OnestBold, sans-serif


    img
      max-height: 100%
      margin-bottom: 1rem
      border-radius: .4rem

    p
      margin-top: 0
      color: $grey600-color
      margin-bottom: 1rem

    .cardDescription
      color: $text-color

    h4
      margin-bottom: 1.5rem


@media (hover: hover)
  .card:hover
    margin-top: -2rem

    button
      color: $white-color
      background-color: $text-color
      border-color: $text-color

      &:hover
        color: $white-color
        background-color: $text-color
        border-color: $text-color

      img
        filter: invert(1)

  .card.cardNews:hover, .card.cardSmall:hover
    margin-top: 0
    a
      text-decoration: underline

@media screen and (max-width: 1280px)
  .card:hover
    margin-top: 0

  .card
    margin-bottom: 1rem