@import '../../theme'

.backToTop
  cursor: pointer
  float: right
  margin-top: 0
  text-align: right
  display: flex
  align-items: center
  gap: 1rem
  justify-self: flex-end
  color: $white-color
  font-family: OnestBold, sans-serif